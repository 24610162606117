import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import GlobalTokens from '@sainsburys-tech/tokens/dist/global/js/json/color/index.json';
import SainsburysColourTokens from '@sainsburys-tech/tokens/dist/sainsburys/js/json/color/index.json';
import SainsburysTypeTokens from '@sainsburys-tech/tokens/dist/sainsburys/js/json/typography/index.json';
import ColourBlock from '@components/colour-block';
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableRow, TableCell } from '@jsluna/table';
import { PrimaryColours, SecondaryColours, PromotionalColours, BaseColours, ExtendedColours, MonochromeColours, SemanticColours, ColourLookup } from '@components/design-tokens';
import FurtherReading from '@shared/colours/further-reading';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const GridList = makeShortcode("GridList");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Button = makeShortcode("Button");
const Dont = makeShortcode("Dont");
const TableCellResult = makeShortcode("TableCellResult");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#colour-palettes"
            }}>{`Colour palettes`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#colour-accessibility"
            }}>{`Colour accessibility`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "colour-palettes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#colour-palettes",
        "aria-label": "colour palettes permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Colour palettes`}</h2>
    <p>{`Sainsbury’s core colours are orange, plum, white and black. Orange is the colour most associated with Sainsbury’s by users, so it's important to use this distinctive asset first and foremost.`}</p>
    <h3>{`Primary palette`}</h3>
    <p>{`For digital experiences, we lean heavily on the use of orange, alongside white and black from the monochrome palette. Plum is used sparingly if needed.`}</p>
    <h4>{`Primary base colours`}</h4>
    <GridList columns="3" mdxType="GridList">
  <ColourBlock fill="#F06C00" mdxType="ColourBlock">Orange Base</ColourBlock>
  <ColourBlock fill="#E55000" mdxType="ColourBlock">Orange Dark</ColourBlock>
  <ColourBlock fill="#7F0442" mdxType="ColourBlock">Plum Base</ColourBlock>
    </GridList>
    <h3>{`Promotional palette`}</h3>
    <p>{`Sainsbury’s promotional palette of colours are red and blue. Use red for when you’re promoting a sale, and use blue when you’re promoting something new.`}</p>
    <h4>{`Promotional base colours`}</h4>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#D50000" mdxType="ColourBlock">Sale Base</ColourBlock>
  <ColourBlock fill="#1976D2" mdxType="ColourBlock">New Base</ColourBlock>
    </GridList>
    <h4>{`Promotional extended colours`}</h4>
    <h5>{`Sale extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#8D0000" mdxType="ColourBlock">Sale Darkest</ColourBlock>
  <ColourBlock fill="#A50000" mdxType="ColourBlock">Sale Darker</ColourBlock>
  <ColourBlock fill="#BD0000" mdxType="ColourBlock">Sale Dark</ColourBlock>
  <ColourBlock fill="#DD2E2E" mdxType="ColourBlock">Sale Light</ColourBlock>
  <ColourBlock fill="#EC8A8A" mdxType="ColourBlock">Sale Lighter</ColourBlock>
  <ColourBlock fill="#FBE6E6" mdxType="ColourBlock">Sale Lightest</ColourBlock>
    </GridList>
    <h5>{`New extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#114E8B" mdxType="ColourBlock">New Darkest</ColourBlock>
  <ColourBlock fill="#135CA3" mdxType="ColourBlock">New Darker</ColourBlock>
  <ColourBlock fill="#1669BA" mdxType="ColourBlock">New Dark</ColourBlock>
  <ColourBlock fill="#428FDA" mdxType="ColourBlock">New Light</ColourBlock>
  <ColourBlock fill="#95C0EA" mdxType="ColourBlock">New Lighter</ColourBlock>
  <ColourBlock fill="#E8F1FB" mdxType="ColourBlock">New Lightest</ColourBlock>
    </GridList>
    <h3>{`Monochrome palette`}</h3>
    <p>{`The monochome palette is a group of colours that can provide text contrast and neutrality to designs.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#000000" mdxType="ColourBlock">Monochrome Black</ColourBlock>
  <ColourBlock fill="#262626" mdxType="ColourBlock">Monochrome Darker</ColourBlock>
  <ColourBlock fill="#404040" mdxType="ColourBlock">Monochrome Dark</ColourBlock>
  <ColourBlock fill="#737373" mdxType="ColourBlock">Monochrome Medium</ColourBlock>
  <ColourBlock fill="#D8D8D8" mdxType="ColourBlock">Monochrome Light</ColourBlock>
  <ColourBlock fill="#F2F2F2" mdxType="ColourBlock">Monochrome Lighter</ColourBlock>
  <ColourBlock fill="#FFFFFF" mdxType="ColourBlock">Monochrome White</ColourBlock>
    </GridList>
    <h3>{`Semantic palette`}</h3>
    <p>{`The semantic palette is a group of colours to communicate key messages throughout an experience. Use red for error messages, green for success messages, blue for important information, and yellow for warnings.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#D50000" mdxType="ColourBlock">Error Base</ColourBlock>
  <ColourBlock fill="#1976D2" mdxType="ColourBlock">Information Base</ColourBlock>
  <ColourBlock fill="#2E7D32" mdxType="ColourBlock">Success Base</ColourBlock>
  <ColourBlock fill="#FFD600" mdxType="ColourBlock">Warning Base</ColourBlock>
    </GridList>
    <h4>{`Semantic extended colours`}</h4>
    <h5>{`Error extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#A50000" mdxType="ColourBlock">Error Darker</ColourBlock>
  <ColourBlock fill="#BD0000" mdxType="ColourBlock">Error Dark</ColourBlock>
  <ColourBlock fill="#DD2E2E" mdxType="ColourBlock">Error Light</ColourBlock>
  <ColourBlock fill="#FBE6E6" mdxType="ColourBlock">Error Lighter</ColourBlock>
    </GridList>
    <h5>{`Success extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#246127" mdxType="ColourBlock">Success Darker</ColourBlock>
  <ColourBlock fill="#296F2C" mdxType="ColourBlock">Success Dark</ColourBlock>
  <ColourBlock fill="#549457" mdxType="ColourBlock">Success Light</ColourBlock>
  <ColourBlock fill="#EAF2EB" mdxType="ColourBlock">Success Lighter</ColourBlock>
    </GridList>
    <h5>{`Information extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#135CA3" mdxType="ColourBlock">Information Darker</ColourBlock>
  <ColourBlock fill="#1669BA" mdxType="ColourBlock">Information Dark</ColourBlock>
  <ColourBlock fill="#428FDA" mdxType="ColourBlock">Information Light</ColourBlock>
  <ColourBlock fill="#E8F1FB" mdxType="ColourBlock">Information Lighter</ColourBlock>
    </GridList>
    <h5>{`Warning extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#C6A600" mdxType="ColourBlock">Warning Darker</ColourBlock>
  <ColourBlock fill="#E2BE00" mdxType="ColourBlock">Warning Dark</ColourBlock>
  <ColourBlock fill="#FFDD2E" mdxType="ColourBlock">Warning Light</ColourBlock>
  <ColourBlock fill="#FFFBE6" mdxType="ColourBlock">Warning Lighter</ColourBlock>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "colour-accessibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#colour-accessibility",
        "aria-label": "colour accessibility permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Colour accessibility`}</h2>
    <p>{`Our standard button labels use Mary Ann ExtraBold at 18px. Please note that this fails to meet WCAG 2.1 AA standards by a small margin, but we don’t recommend increasing the text size. You can read more below.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <Button variant="filled" style={{
          borderRadius: '2px',
          background: '#F06C00 !important',
          fontFamily: SainsburysTypeTokens.typography.brand['font-family'],
          fontWeight: SainsburysTypeTokens.typography['font-weight']['extra-bold'],
          fontSize: '18px'
        }} mdxType="Button">
      MaryAnn, 18px
    </Button>
  </Do>
  <Dont mdxType="Dont">
    <Button Element="span" variant="filled" style={{
          borderRadius: '2px',
          backgroundColor: '#F06C00' + ' !important',
          fontFamily: SainsburysTypeTokens.typography.brand['font-family'],
          fontWeight: SainsburysTypeTokens.typography['font-weight']['extra-bold'],
          fontSize: '20px'
        }} mdxType="Button">
      MaryAnn, 20px
    </Button>
  </Dont>
    </Guideline>
    <p>{`Orange (#F06C00) links within body text on White (#FFFFFF) should always be bold.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <div>
      This{' '}
      <span style={{
            display: 'inline',
            fontWeight: 'bold',
            color: '#F06C00'
          }}>
        link text
      </span>{' '}
      is in bold.
    </div>
  </Do>
  <Dont mdxType="Dont">
    <div>
      This{' '}
      <span style={{
            display: 'inline',
            color: '#F06C00'
          }}>link text</span> is
      not in bold.
    </div>
  </Dont>
    </Guideline>
    <p>{`Don’t use Orange (#F06C00) on White (#FFFFFF) for small text, such as captions, and icons.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <span style={{
          fontSize: 'small'
        }}> The image caption is black</span>
  </Do>
  <Dont mdxType="Dont">
    <span style={{
          fontSize: 'small',
          color: '#F06C00'
        }}>
      The image caption is our primary orange
    </span>
  </Dont>
    </Guideline>
    <h3>{`Sainsbury's orange accessibility`}</h3>
    <p>{`Luna aims to meet the Web Content Accessibility Guidelines 2.1 AA standard for colour contrast. But there’s one single exception to this around using the Sainsbury's orange in certain contexts.`}</p>
    <p>{`The two Sainsbury’s oranges, Orange Base (#F06C00) and Orange Dark (#E55000), are not WCAG 2.1AA compliant when used with either Monochrome White (#FFFFFF) or Monochrome Lighter (#F2F2F2) at body text size. However, as orange is Sainsbury's main brand colour, we sometimes need to use it at body text size anyway. An example is in our button labels, as shown above.`}</p>
    <p>{`You can see a summary of the issue below.`}</p>
    <TableContainer className="ln-u-margin-bottom" mdxType="TableContainer">
  <TableHeader mdxType="TableHeader">
    <TableHeaderRow mdxType="TableHeaderRow">
      <TableHeaderCell mdxType="TableHeaderCell">Element</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">
        WCAG 2.1 AA contrast ratio recommendation
      </TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">
        Orange Base (#F06C00) on Monochrome White (#FFFFFF) contrast ratio
      </TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">
        Orange Dark (#E55000) on Monochrome White (#FFFFFF) contrast ratio
      </TableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Body text</TableCell>
      <TableCell mdxType="TableCell">4:5:1</TableCell>
      <TableCell mdxType="TableCell">3:06:1 (fail)</TableCell>
      <TableCell mdxType="TableCell">3:83:1 (fail)</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Large text (18pt or 14pt bold)</TableCell>
      <TableCellResult alignLeft={true} mdxType="TableCellResult">3:1</TableCellResult>
      <TableCellResult alignLeft={true} success={true} mdxType="TableCellResult">
        3:06:1 (pass)
      </TableCellResult>
      <TableCellResult alignLeft={true} success={true} mdxType="TableCellResult">
        3:83:1 (pass)
      </TableCellResult>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Icons, shapes and keylines</TableCell>
      <TableCellResult alignLeft={true} mdxType="TableCellResult">3:1</TableCellResult>
      <TableCellResult alignLeft={true} success={true} mdxType="TableCellResult">
        3:06:1 (pass)
      </TableCellResult>
      <TableCellResult alignLeft={true} success={true} mdxType="TableCellResult">
        3:83:1 (pass)
      </TableCellResult>
    </TableRow>
  </TableBody>
    </TableContainer>
    <hr></hr>
    <FurtherReading mdxType="FurtherReading" />
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      